import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useToasts } from 'react-toast-notifications';
import { Container } from '@material-ui/core';

import UserDetails from './components';
import { MAIN_TITLE } from '../../assets/styles/variables';
import Loader from '../../components/Loader';
import { get } from '../../shared/api';
import { getErrorMessage } from '../../shared/helpers';

const UserProfile = () => {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const loadUserProfile = () => {
    setIsLoading(true);

    get('/identity/myProfile')
      .then((data) => {
        setUserData(data);
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadUserProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Profile | {MAIN_TITLE}</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <UserDetails
            onDetailsUpdated={loadUserProfile}
            id={userData.id}
            firstName={userData.firstName}
            lastName={userData.lastName}
            email={userData.email}
            phoneNumber={userData.phoneNumber}
            photosName={userData.photosName}
            additionalEmails={userData.additionalEmails}
            areNotificationsEnabled={userData.areNotificationsEnabled}
          />
        </Container>
      )}
    </>
  );
};

export default UserProfile;
