import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Controller, useForm } from 'react-hook-form';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField, Typography
} from '@material-ui/core';

import Loader from '../../../components/Loader';
import { put } from '../../../shared/api';
import { getErrorMessage } from '../../../shared/helpers';
import { selectUser } from '../../../store/selectors/auth.selectors';
import { useSelector } from 'react-redux';
import { ROLE_ADMIN } from '../../../shared/contants'; 

const UserEditForm = ({ isOpen, onSuccess, onCancel, data }) => {
    const { control, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const currentUser = useSelector(selectUser);
    const { addToast } = useToasts();

    useEffect(() => {
        reset(data);
    }, [data]);

    useForm({
        defaultValues: {
            ...data,
            additionalEmails: Array.isArray(data.additionalEmails)
                ? data.additionalEmails.join(', ')
                : '', // Convert array to a comma-separated string for the input field
        },
    });

    const handleCancel = () => {
        onCancel && onCancel();
    };

    const onFormSubmit = async (formData) => {
        setIsLoading(true);
        const { id, ...data } = formData;

        if (typeof data.additionalEmails === 'string') {
            data.additionalEmails = data.additionalEmails
                .split(',')
                .map(email => email.trim())
                .filter(email => email);
        } else if (!Array.isArray(data.additionalEmails)) {
            data.additionalEmails = [];
        }

        await put(`/identity/updateProfile/${id}`, data)
            .then((response) => {
                if (response) {
                    setErrorMessage('');
                    addToast(
                        'User profile updated successfully!',
                        { appearance: 'success' }
                    );

                    onSuccess && onSuccess();
                }
            })
            .catch((error) => {
                setErrorMessage(getErrorMessage(error));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Dialog open={isOpen} onClose={handleCancel}>
                <DialogTitle>Edit my profile details</DialogTitle>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="First name"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Last name"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Email"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Phone number"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="photosName"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Photos folder"
                                            variant="outlined"
                                            fullWidth
                                            disabled={currentUser.role !== ROLE_ADMIN} // Disable if not ROLE_ADMIN
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="additionalEmails"
                                    control={control}
                                    defaultValue={data.additionalEmails ? data.additionalEmails.join(', ') : ''}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Additional Emails (comma separated)"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="example1@mail.com, example2@mail.com"
                                        />
                                    )}
                                />
                            </Grid>
                            {errorMessage && (
                                <Grid item xs={12}>
                                    <Typography color="error" variant="body2">
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary">
                            Save
                        </Button>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {isLoading && <Loader />}
        </>
    );
};

export default UserEditForm;
